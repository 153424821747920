import { useEffect, useRef, useState } from "react";

import "./App.scss";

const Player = () => {
  const [playing, setPlaying] = useState(false);
  const [audioUrl, setAudioUrl] = useState("");
  const audioRef = useRef<HTMLAudioElement>();

  const toggle = () => {
    setPlaying(!playing);

    if (playing) {
      audioRef.current?.pause();
    } else {
      audioRef.current?.play();
    }
  };

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    fetch(params.audioUrl)
      .then(res => {
        if (res.status === 200) {
          setAudioUrl(params.audioUrl);
        }
      })
      .catch((err) => {console.log(err)});

  }, []);

  return audioUrl ? (
    <button className={`article-audio-button-amp  ${playing ? "play" : "default"}`} onClick={toggle}>
      {audioUrl ? (
        <audio
          ref={(ref) => {
            if (ref) {
              audioRef.current = ref;
            }
          }}
        >
          <source src={audioUrl} type="audio/mp3" />
        </audio>
      ) : null}
      <>
        {playing ? (
          <img
            className="article-audio-icon-amp"
            src="assets/icons/audioHeadphones.svg"
            alt="Audio Content Playing"
          />
        ) : (
          <img
            className="article-audio-icon-amp"
            src="assets/icons/audioHeadphones.svg"
            alt="Stop Audio Content"
          />
        )}
      </>
    </button>
  ) : null;
};

export default Player;
